<template>
  <b-card>

    <b-row>

      <!-- User Info: Left col -->
      <b-col
        cols="12"
        class="d-flex justify-content-between flex-column"
      >
        <!-- User Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <b-avatar
            :src="require('@/assets/images/avatar/groups/'+(group.id_avatar||'null')+'.png?v4')"

            :text="avatarText(group.name)"
            :variant="`light-primary`"
            size="104px"
            rounded
          />
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0 break-word">
                {{ group.name }}
              </h4>
            </div>
            <div class="d-flex flex-wrap">
              <template v-if="$can('update', 'SchoolGroups') && canEdit">
                <b-button
                  class="mr-25 mb-25"
                  :to="{ 
                    name: 'school-groups-edit', 
                    params: { 
                      id: school.id, 
                      group_id: group.id, 
                    } 
                  }"
                  variant="primary"
                  v-if="!group.deleted_at"
                >
                  {{ $t('forms.edit')}}
                </b-button>
              </template>
              <template v-if="$can('delete', 'SchoolGroups')">
                <b-button
                  variant="outline-danger"
                  class="mr-25 mb-25"
                  v-if="!group.deleted_at"
                  @click="deleteModel"
                >
                  {{ $t('forms.delete')}}
                </b-button>
              </template>
              
              <b-button
                variant="outline-secondary"
                type="button"
                class="mr-25 mb-25"
                @click="hasHistory() 
                  ? $router.go(-1) 
                  : $router.push({ name: 'school-groups-list', params: { id: school.id } })"
                >
                {{ $t('forms.go_back') }}
              </b-button>
            </div>
          </div>
        </div>
      </b-col>

      <!-- Right Col: Table -->
      <b-col
        cols="12"
      >
        <table class="mt-2 w-100">
          
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="SmileIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">{{$t('Dreamers')}}</span>
            </th>
            <td class="pb-50">
              {{ dreamers_length  }}
            </td>
          </tr>
        
         <tr v-if="group.created_at">
            <th class="pb-50">
              <feather-icon
                icon="CalendarIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">{{$t('fields.created_at')}}</span>
            </th>
            <td>
              {{ new Date(group.created_at).toLocaleDateString() }}
            </td>
          </tr>
          <!-- <tr v-if="group.updated_at">
            <th>
              <feather-icon
                icon="EditIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">{{$t('dash.cards.last_update')}}</span>
            </th>
            <td>
              {{ new Date(group.updated_at).toLocaleDateString() }}
            </td>
          </tr> -->
        </table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { deleteSchoolGroup } from '@/api/routes'
import {
  BCard, BButton, BAvatar, BRow, BCol,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'

export default {
  components: {
    BCard, BButton, BRow, BCol, BAvatar,
  },
  props: {
    group: {
      type: Object,
      required: true,
    },
    school:{
      type: Object,
      required:true
    },
    canEdit:{
      type: Boolean,
      required: false,
      default: true
    }
  },
  setup() {
    return {
      avatarText,
    }
  },
  computed:{
    dreamers_length(){
      if(this.group.id == 0) return this.group.dreamers.length
      
      return this.group.dreamers.filter(item => item.linked).length
    }
  },
  methods:{
    hasHistory () { 
       return window.history.length > 2 
    },
    deleteModel(){
      this.$bvModal
        .msgBoxConfirm(this.$t('forms.delete_group_confirmation'), {
          title: this.$t('forms.delete'),
          size: 'sm',
          okVariant: 'primary',
          okTitle: this.$t('Yes'),
          cancelTitle: this.$t('No'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if(value){
            let data = {
              'id': this.group.id
            }
            this.$http.post(deleteSchoolGroup, data).then( response =>{
              // console.log(response)
              if(response.data.status == 200){
                //this.$emit('refetch-data')
                this.$router.push({ name: 'school-groups-list' });
                this.makeToast('success', this.$t('Success'), this.$t('table.delete_success'));

              } else {
                  this.makeToast('danger', this.$t('Error'), this.$t('table.delete_error'));
              }

            }).catch(error => {
              // console.log("Err -> ", error)
            })
          }
        })
    },
    makeToast(variant = null, tit ='',msg = '') {
      this.$bvToast.toast(msg, {
        title: tit,
        variant,
        solid: false,
      })
    },
  }
}
</script>

<style>

</style>
