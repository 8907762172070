var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('b-row',[_c('b-col',{staticClass:"d-flex justify-content-between flex-column",attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex justify-content-start"},[_c('b-avatar',{attrs:{"src":require('@/assets/images/avatar/groups/'+(_vm.group.id_avatar||'null')+'.png?v4'),"text":_vm.avatarText(_vm.group.name),"variant":"light-primary","size":"104px","rounded":""}}),_c('div',{staticClass:"d-flex flex-column ml-1"},[_c('div',{staticClass:"mb-1"},[_c('h4',{staticClass:"mb-0 break-word"},[_vm._v(" "+_vm._s(_vm.group.name)+" ")])]),_c('div',{staticClass:"d-flex flex-wrap"},[(_vm.$can('update', 'SchoolGroups') && _vm.canEdit)?[(!_vm.group.deleted_at)?_c('b-button',{staticClass:"mr-25 mb-25",attrs:{"to":{ 
                  name: 'school-groups-edit', 
                  params: { 
                    id: _vm.school.id, 
                    group_id: _vm.group.id, 
                  } 
                },"variant":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('forms.edit'))+" ")]):_vm._e()]:_vm._e(),(_vm.$can('delete', 'SchoolGroups'))?[(!_vm.group.deleted_at)?_c('b-button',{staticClass:"mr-25 mb-25",attrs:{"variant":"outline-danger"},on:{"click":_vm.deleteModel}},[_vm._v(" "+_vm._s(_vm.$t('forms.delete'))+" ")]):_vm._e()]:_vm._e(),_c('b-button',{staticClass:"mr-25 mb-25",attrs:{"variant":"outline-secondary","type":"button"},on:{"click":function($event){_vm.hasHistory() 
                ? _vm.$router.go(-1) 
                : _vm.$router.push({ name: 'school-groups-list', params: { id: _vm.school.id } })}}},[_vm._v(" "+_vm._s(_vm.$t('forms.go_back'))+" ")])],2)])],1)]),_c('b-col',{attrs:{"cols":"12"}},[_c('table',{staticClass:"mt-2 w-100"},[_c('tr',[_c('th',{staticClass:"pb-50"},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"SmileIcon"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t('Dreamers')))])],1),_c('td',{staticClass:"pb-50"},[_vm._v(" "+_vm._s(_vm.dreamers_length)+" ")])]),(_vm.group.created_at)?_c('tr',[_c('th',{staticClass:"pb-50"},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"CalendarIcon"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t('fields.created_at')))])],1),_c('td',[_vm._v(" "+_vm._s(new Date(_vm.group.created_at).toLocaleDateString())+" ")])]):_vm._e()])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }